import TOS from "./views/TOS.vue"

<template>
  <div class="About-page-container">
    <div id="about-section">
        <h2 id="about-section-header">About AHJ Registry</h2>
        <h4>Version 2.1.3</h4>
        <h4>Copyright 2023 SunSpec Alliance. All rights reserved.</h4>
        <!-- <b-nav-item href="#/TOS">Terms of Service</b-nav-item> -->
    </div>
    <hr>
  </div>
</template>

<script>

export default {
    data() {
        return {
            Email: '',
            clientPhotos: ['sunspec_alliance.png'],
            clientPhotoLocation: "images/AboutPage/clients/",
        }
    },
    computed: {
        LoggedIn(){
            return this.$store.getters.loggedIn;
        }
    },
    components: {
    // "feature-section": FeatureSection,
    // "account-feature-description": AccountFeatureDescription
  },
}
</script>

<style scoped>

hr {
    margin: 0;
    padding: 0;
    font-size: 1.5em;
}

h1 {
    font-size: 4rem;
}

h2 {
    font-size: 4rem;
}

h4 {
    font-size: 1rem;
}

h5 {
    font-size: 1.2rem;
}

#about-section > * {
    text-align: center;
    width: 70%;
    margin: 30px auto 20px;
}

#about-section-header {
    margin: 1em auto 0.8em;
}

</style>
