<template>
  <div class="API-page-container">
    <div id="API-section">
      <h1 id="API-section-header">SunSpec AHJ Registry API</h1>
      <api-form ref="apiFormComponent"></api-form>
      <p>SunSpec AHJ Registry makes it fast and easy for designers and installers of Distributed Energy Resources (solar PV & battery systems, EV chargers, heat pumps, and the like) to determine which Authority Having Jurisdiction (AHJ) can say “yes” or “no” to an installation project.<br/><br/>Submit a street address or lat/long coordinates of a project to SunSpec AHJ Registry, via API or web form, to immediately see the name of the AHJ (city, county, or state), the building & electrical codes used by that AHJ, and other pertinent information. A process that might take 15-20 minutes by Google searching now takes seconds, saving no less than $10 to $20 per installation.<br/><br/>
      The SunSpec AHJ Registry service is brought to you by the SunSpec Alliance, a non-profit trade association with a mission to expand the market for Distributed Energy Resources, and is offered as an exclusive benefit to SunSpec members. To request your AHJ Registry API Token, contact <a href="mailto: membership@sunspec.org">membership@sunspec.org</a>.
      </p>
      <div class="container">
        <div class="center">
          <b-button v-b-modal.my-modal-2 @click='ResetAPIForm'>Request an API Token</b-button>
        </div>
      </div>
      <h2>Introduction</h2>
      <p>The AHJ Registry sends and receives JSON objects following the definitions of the <a href="https://orangebutton.io/">Orange Button OpenAPI</a> taxonomy. Visit the <a href="https://openobeditor.sunspec.org/">Orange Button OpenAPI Editor</a> to explore the taxonomy.
      <br/>
      The objects used are:
      </p>
      <ul>
        <li><a href="https://openobeditor.sunspec.org/#/?view=AuthorityHavingJurisdiction">AuthorityHavingJurisdiction</a></li>
        <li><a href="https://openobeditor.sunspec.org/#/?view=Address">Address</a></li>
        <li><a href="https://openobeditor.sunspec.org/#/?view=AHJInspection">AHJInspection</a></li>
        <li><a href="https://openobeditor.sunspec.org/#/?view=Contact">Contact</a></li>
        <li><a href="https://openobeditor.sunspec.org/#/?view=DocumentSubmissionMethod">DocumentSubmissionMethod</a></li>
        <li><a href="https://openobeditor.sunspec.org/#/?view=EngineeringReviewRequirement">EngineeringReviewRequirement</a></li>
        <li><a href="https://openobeditor.sunspec.org/#/?view=FeeStructure">FeeStructure</a></li>
        <li><a href="https://openobeditor.sunspec.org/#/?view=Location">Location</a></li>
        <li><a href="https://openobeditor.sunspec.org/#/?view=PermitIssueMethod">PermitIssueMethod</a></li>
      </ul>
      <h2>Authentication</h2>
      <p>The AHJ Registry API uses token-based authentication. Follow the steps in Creating an Account and Getting an API Token to get an API token. Look at the API section to learn what functionality is available and how to use the AHJ Registry API.</p>
      <h3>Creating an Account</h3>
      <p>To create an account with the AHJ Registry, follow these steps:</p>
      <ol>
        <li>Visit the AHJ Registry, and click Register in the top-right corner.</li>
        <li>Fill out the sign up form, and click Sign up.</li>
        <li>Check your email to see a confirmation email sent from ahjregistry@sunspec.org. Open it and click the link to activate your account.</li>
        <li>After clicking the link, you will be directed to the login page. Another email will be sent to confirm your account was successfully activated.</li>
      </ol>
      <h3>Using your API Token</h3>
      <p>An API token is required on every request to AHJ Registry API endpoints. The API token should be included in the header of the HTTP request as follows:
        <br/>
      <pre>
      Authorization: Token &lt;api_token&gt;
      # Example
      Authorization: Token 1dca175cd4d363ef6cb5e0a5425844a9f6cd247a3
      </pre>
      </p>
      <h2>AHJ Search API</h2>
      <h3>AHJ Search</h3>
      <p>The AHJ Registry provides a single API endpoint for searching AHJs using various search parameters. The following subsections describe how to use each search parameter.</p>
      <h3>Endpoint:</h3>
      <pre><p>POST  https://ahjregistry.myorangebutton.com/api/v1/ahj/</p></pre>
      <h3>Searching by Address</h3>
      <p>Searching by Address finds the AHJs whose jurisdiction contains a given address using the <a href="https://www.census.gov/geographies/mapping-files/time-series/geo/tiger-line-file.html">Census TIGER/Line Legal Boundary Shapefiles</a>. To use this search, send a POST request with the following Orange Button OpenAPI Address object:</p>
      <pre>
    {
      "Address": {
        "AddrLine1": 
          {"Value": "&lt;value&gt;"},
        "AddrLine2": 
          {"Value": "&lt;value&gt;"},
        "AddrLine3": 
          {"Value": "&lt;value&gt;"},
        "City": 
          {"Value": "&lt;value&gt;"},
        "County": 
          {"Value": "&lt;value&gt;"},
        "StateProvince": 
          {"Value": "&lt;value&gt;"},
        "ZipPostalCode": 
          {"Value": "&lt;value&gt;"}
      }
    }
      </pre>
      <p>Example:</p>
      <pre>
    {
      "Address": {
        "AddrLine1": 
          {"Value": "106 W 1st St"},
        "City": 
          {"Value": "Los Angeles"},
        "StateProvince": 
          {"Value": "CA"},
        "ZipPostalCode": 
          {"Value": "90012"}
      }
    }
      </pre>
      <P>
      Note that not all Orange Button Address fields are required. The implementation of this search parameter using Google Geocoding API is used to convert the address into latitude-longitude coordinates. It is recommended to include enough fields of the Orange Button Address such that Google Maps can find the correct address.
      <br/>
      The Address search parameter can be included with all other search parameters, except the Location search parameter. If a Location search parameter is included, the Address search parameter is ignored.
      </P>
      <h3>Searching by Location</h3>
      <p>Searching by Location finds the AHJs whose jurisdiction contains given latitude-longitude coordinates using the <a href="https://www.census.gov/geographies/mapping-files/time-series/geo/tiger-line-file.html">Census TIGER/Line Legal Boundary Shapefiles</a>. To use this search parameter, send a POST request with the following Orange Button OpenAPI Location object:</p>
      <pre>
    {
      "Location": {
        "Latitude": 
        {"Value": "&lt;value&gt;"},
        "Longitude": 
        {"Value": "&lt;value&gt;"}
      }
    }
      </pre>
      <p>For example:</p>
      <pre>
    {
      "Location": {
        "Latitude": 
          {"Value": -118.2437},
        "Longitude": 
          {"Value": 34.0522}
      }
    }
      </pre>
      <p>
        Both the Latitude and Longitude fields are required.
        <br/>
        The Location search parameter can be included with all other search parameters, except the Address search parameter. If a Location search parameter is included, the Address search parameter is ignored.
      </p>
      <h3>Searching by Other Parameters</h3>
      <p>
        The other search parameters offered filter based on the field values on the Orange Button AuthorityHavingJurisdiction object.
        <br/>
        The fields that can be used to filter AHJs are:
      </p>
      <ul>
        <li><a href="https://openobeditor.sunspec.org/#/?view=AHJCode">AHJCode</a></li>
        <li><a href="https://openobeditor.sunspec.org/#/?view=AHJID">AHJID</a></li>
        <li><a href="https://openobeditor.sunspec.org/#/?view=AHJLevelCode">AHJLevelCode</a></li>
        <li><a href="https://openobeditor.sunspec.org/#/?view=AHJName">AHJName</a></li>
        <li><a href="https://openobeditor.sunspec.org/#/?view=BuildingCodes">BuildingCodes</a></li>
        <li><a href="https://openobeditor.sunspec.org/#/?view=ElectricCodes">ElectricCodes</a></li>
        <li><a href="https://openobeditor.sunspec.org/#/?view=FireCodes">FireCodes</a></li>
        <li><a href="https://openobeditor.sunspec.org/#/?view=ResidentialCodes">ResidentialCodes</a></li>
        <li><a href="https://openobeditor.sunspec.org/#/?view=WindCodes">WindCodes</a></li>
        <li><a href="https://openobeditor.sunspec.org/#/?view=StateProvince">StateProvince</a></li>
      </ul>
      <p>
        Some of these search parameters accept one value (single-select), while others accept an array of values (multi-select). To use these search parameters, send a POST request with any of the following Orange Button OpenAPI objects:
        <br/>
        Single-value filters:
      </p>
      <pre>
    {
      "&lt;field_name&gt;": 
          {"Value": "&lt;value&gt;"}
    }
      </pre>
      <p>For example, AHJs can be filtered by AHJID:</p>
      <pre>
    {
      "AHJID" : {"Value": "63e32227-7a31-4a0c-a715-20d46315cc9e"}
    }
      </pre>
      <p>The single-select fields for filtering are:</p>
      <ul>
        <li><a href="https://openobeditor.sunspec.org/#/?view=AHJCode">AHJCode</a></li>
        <li><a href="https://openobeditor.sunspec.org/#/?view=AHJID">AHJID</a></li>
        <li><a href="https://openobeditor.sunspec.org/#/?view=AHJLevelCode">AHJLevelCode</a></li>
        <li><a href="https://openobeditor.sunspec.org/#/?view=AHJName">AHJName</a></li>
        <li><a href="https://openobeditor.sunspec.org/#/?view=StateProvince">StateProvince</a></li>
      </ul>
      <p>Multi-select filters:</p>
      <pre>
    {
      "&lt;field_name&gt;" : 
      {"Value": "&lt;value&gt;"}
    }
      </pre>
      <p>For example, AHJs can be filtered by BuildingCodes:</p>
      <pre>
    {
      "BuildingCodes" : [
        {"Value": "2021IBC"},
        {"Value": "2018IBC"}
      ]
    }
      </pre>
      <p>The multi-select fields for filtering are:</p>
      <ul>
        <li><a href="https://openobeditor.sunspec.org/#/?view=BuildingCodes">BuildingCodes</a></li>
        <li><a href="https://openobeditor.sunspec.org/#/?view=ElectricCodes">ElectricCodes</a></li>
        <li><a href="https://openobeditor.sunspec.org/#/?view=FireCodes">FireCodes</a></li>
        <li><a href="https://openobeditor.sunspec.org/#/?view=ResidentialCodes">ResidentialCodes</a></li>
        <li><a href="https://openobeditor.sunspec.org/#/?view=WindCodes">WindCodes</a></li>
      </ul>
      <h3>Combining Search Parameters</h3>
      <p>Any combination of search parameters can be used like so, excluding combining Address and Location search parameters.
      <br/>
      Here is an example of coming Address, AHJLevelCode, and FireCode filters in a search:
      </p>
      <pre>
    {
      "Address": {
        "AddrLine1": 
          {"Value": "106 W 1st St"},
        "City": 
          {"Value": "Los Angeles"},
        "StateProvince": 
          {"Value": "CA"},
        "ZipPostalCode": 
          {"Value": "90012"}
      },
      "AHJLevelCode": [
        {"Value": "061"}
      ],
      "FireCodes": [
        {"Value": "2021IFC"},
        {"Value": "2018IFC"}
      ]
    }
      </pre>
      <h3>API Response Example</h3>
      <p>Here is an example response from the API.</p>
      <pre>
    {
      "count": 1,
      "next": null,
      "previous": null,
      "AuthorityHavingJurisdictions": [
        {
          "AHJID": 
            {"Value": "f97ea81a-f9c4-4195-889e-ad414b736ce5"},
          "AHJCode": 
            {"Value": "CA-0686300"},
          "AHJLevelCode": 
            {"Value": "162"},
          "AHJName": 
            {"Value": "Woodlake city"},
          "Description": 
            {"Value": ""},
          "DocumentSubmissionMethodNotes": 
            {"Value": ""},
          "PermitIssueMethodNotes": 
            {"Value": ""},
          "EstimatedTurnaroundDays": 
            {"Value": null},
          "FileFolderURL": 
            {"Value": ""},
          "URL": 
            {"Value": "cityofwoodlake.com"},
          "BuildingCode": 
            {"Value": "2018IBC"},
          "BuildingCodeNotes": 
            {"Value": "California Building Code 2019"},
          "ElectricCode": 
            {"Value": "2017NEC"},
          "ElectricCodeNotes": 
            {"Value": "California Electrical Code 2019"},
          "FireCode": 
            {"Value": "2018IFC"},
          "FireCodeNotes": 
            {"Value": "California Fire Code 2019"},
          "ResidentialCode": 
            {"Value": "2018IRC"},
          "ResidentialCodeNotes": 
            {"Value": "California Residential Code 2019"},
          "WindCode": 
            {"Value": "ASCE716"},
          "WindCodeNotes": 
            {"Value": ""},
          "Address": {
            "AddrLine1": 
              {"Value": "350 N Valencia Blvd"},
            "AddrLine2": 
              {"Value": ""},
            "AddrLine3": 
              {"Value": ""},
            "City": 
              {"Value": "Woodlake"},
            "Country": 
              {"Value": "USA"},
            "County": 
              {"Value": ""},
            "StateProvince": 
              {"Value": "CA"},
            "ZipPostalCode": 
              {"Value": "93286"},
            "Description": 
              {"Value": ""},
            "AddressType": 
              {"Value": ""},
            "Location": {
              "Altitude": 
                {"Value": null},
              "Elevation": 
                {"Value": null},
              "Latitude": 
                {"Value": 36.418297},
              "Longitude": 
                {"Value": -119.098582},
              "Description": 
                {"Value": ""},
              "LocationDeterminationMethod": 
                {"Value": ""},
              "LocationType": 
                {"Value": ""}
            }
          },
          "Contacts": [
              {
              "FirstName": 
                {"Value": "Ramon"},
              "MiddleName": 
                {"Value": ""},
              "LastName": 
                {"Value": "Lara"},
              "HomePhone": 
                {"Value": ""},
              "MobilePhone": 
                {"Value": ""},
              "WorkPhone": 
                {"Value": "(559)564-8055"},
              "ContactType": 
                {"Value": ""},
              "ContactTimezone": 
                {"Value": ""},
              "Description": 
                {"Value": ""},
              "Email": 
                {"Value": "lwaters@ci.woodlake.ca.us"},
              "Title": 
                {"Value": "City Administrator"},
              "URL": 
                {"Value": ""},
              "PreferredContactMethod": 
                {"Value": ""},
              "Address": {
                "AddrLine1": 
                  {"Value": ""},
                "AddrLine2": 
                  {"Value": ""},
                "AddrLine3": 
                  {"Value": ""},
                "City": 
                  {"Value": ""},
                "Country": 
                  {"Value": ""},
                "County": 
                  {"Value": ""},
                "StateProvince": 
                  {"Value": ""},
                "ZipPostalCode": 
                  {"Value": ""},
                "Description": 
                  {"Value": ""},
                "AddressType": 
                  {"Value": ""},
                "Location": null
              }
            }
          ],
          "AHJInspections": [
            {
              "InspectionType": 
                {"Value": "Final"},
              "AHJInspectionName": 
                {"Value": "Post-Installation Electrical"},
              "AHJInspectionNotes": 
                {"Value": "All installations must be inspected."},
              "Description": 
                {"Value": ""},
              "FileFolderURL": 
                {"Value": ""},
              "TechnicianRequired": 
                {"Value": true},
              "Contacts": [
                {
                  "FirstName": 
                    {"Value": "Jim"},
                  "MiddleName": 
                    {"Value": "Garner"},
                  "LastName": 
                    {"Value": "(559)586-8582"},
                  "HomePhone": 
                    {"Value": ""},
                  "MobilePhone": 
                    {"Value": ""},
                  "WorkPhone": 
                    {"Value": ""},
                  "ContactType": 
                    {"Value": ""},
                  "ContactTimezone": 
                    {"Value": ""},
                  "Description": 
                    {"Value": ""},
                  "Email": 
                    {"Value": ""},
                  "Title": 
                    {"Value": ""},
                  "URL": 
                    {"Value": ""},
                  "PreferredContactMethod": 
                    {"Value": ""  },
                  "Address": {
                    "AddrLine1": 
                      {"Value": "4700 Elmore Road"},
                    "AddrLine2": 
                      {"Value": ""},
                    "AddrLine3": 
                      {"Value": ""},
                    "City": 
                      {"Value": "Anchorage"},
                    "Country": 
                      {"Value": "USA"},
                    "County": 
                      {"Value": "Anchorage Municipality"},
                    "StateProvince": 
                      {"Value": "AK"},
                    "ZipPostalCode": 
                      {"Value": "99507"},
                    "Description": 
                      {"Value": ""},
                    "AddressType": 
                      {"Value": "Mailing"},
                    "Location": null
                  }
                }
              ]
            }
          ],
          "DocumentSubmissionMethods": [
            {"Value": "SolarApp"},
            {"Value": "Email"}
          ],
          "PermitIssueMethods": [
            {"Value": "InPerson"},
            {"Value": "SolarApp"}
          ],
          "EngineeringReviewRequirements": [
            {
              "Description": 
                {"Value": ""},
              "EngineeringReviewType": 
                {"Value": "FireMarshal"},
              "RequirementLevel": 
                {"Value": "Optional"},
              "RequirementNotes": 
                {"Value": ""},
              "StampType": 
                {"Value": ""}
            }
          ],
          "FeeStructures": [
            {
              "FeeStructureID": 
                {"Value": "fb9da0c4-2fb9-4606-83bc-fdb3d6293776"},
              "FeeStructureName": 
                {"Value": "Application Submission Fee"},
              "FeeStructureType": 
                {"Value": "Flat"},
              "Description": 
                {"Value": "There is a $25 application fee."}
            }
          ]
        }
      ]
    }
      </pre>
      <h3>API Response Metadata and Pagination</h3>
      <p>In the example from the previous subsection, on the same level as the AuthorityHavingJurisdictions array in the API response, are three fields:</p>
      <ul>
        <li>count: The number of AHJs returned in the AuthorityHavingJurisdictions array.</li>
        <li>next: A url to the API endpoint to retrieve the next page of results. It is null if there is no next page.</li>
        <li>prev: A url to the API endpoint to retrieve the previous page of results. It is null if there is no previous page.</li>
      </ul>
    </div>
  </div>
</template>

<script>
import APIForm from "../components/APIForm.vue";
export default {
  model: {
    event: 'event-open-modal'
  },
  methods: {
    ResetAPIForm(){
      this.$refs.apiFormComponent.ResetModal();
    },
  },
  components: {
    "api-form": APIForm,
  },
    computed: {
        LoggedIn(){
            return this.$store.getters.loggedIn;
        }
    },
}
</script>

<style scoped>
h1 {
    font-size: 4rem;
}
h2 {
    font-size: 3rem;
}
h3 {
  font-size: 2rem;
}
h4 {
    font-size: 1rem;
}
#API-section > * {
    text-align: left;
    width: 70%;
    margin: 30px auto 20px;
}
#API-section-header {
    text-align: center;
    margin: 1em auto 0.8em;
}
.container {
  height: 50px;
  position: relative;
  /* border: 3px solid green; */
}

.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>
